import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";

import Layout from "../components/layout";
import SEO from "../components/seo";

function update() {
  try {
    const hashValue = document.getElementById("hash").value;
    let link = "magnet:?xt=urn:btih:" + hashValue;

    const nameValue = document.getElementById("name").value;
    if (nameValue !== undefined && nameValue.length > 0) {
      link = link + "&dn=" + encodeURIComponent(nameValue);
    }

    if (hashValue && hashValue.length > 0) {
      document.getElementById("link").href = link;
      document.getElementById("show-link").hidden = false;
      document.getElementById("show-link").innerText = link;
    } else {
      document.getElementById("link").href = "/#";
      document.getElementById("link").href = "/#";
      document.getElementById("show-link").hidden = true;
      document.getElementById("show-link").innerText = "";
    }
  } catch (e) {
    console.error("Exception thrown", e.stack);
    document.getElementById("show-link").innerText = "Unable to Generate";
  }
}

const IndexPage = () => (
  <Layout>
    <div className="d-flex flex-column justify-content-center align-items-center">
      <SEO />
      <h1 className="title-text d-none d-lg-inline-block">
        Magnet Link Generator
      </h1>
      <h2 className="title-text d-none d-md-inline-block d-lg-none">
        Magnet Link Generator
      </h2>
      <h4 className="title-text d-md-none">Magnet Link Generator</h4>
      <form className="mx-auto">
        <div class="form-group">
          <label for="hash">Torrent Hash *</label>
          <input
            class="form-control"
            id="hash"
            placeholder="Enter Hash"
            onChange={update}
          />
        </div>
        <div class="form-group mb-5">
          <label for="name">Torrent Name</label>
          <input
            class="form-control"
            id="name"
            placeholder="Enter Name"
            onChange={update}
          />
        </div>
        <a id="link" className="mx-auto" href="/#">
          <div
            onClick={() => {
              const hashValue = document.getElementById("hash").value;
              if (!hashValue || hashValue.length === 0) {
                alert("Please enter a valid Torrent Hash");
              }
            }}
            class="magnetic-link-button"
          >
            Magnetic Link
          </div>
        </a>
      </form>
      <label id="show-link" className="show-link"></label>
    </div>
  </Layout>
);

export default IndexPage;
